<template>
    <!--
    Страница списка объектов недвижимости
    -->
    <el-container direction="vertical">
        <dom-table
            :items="properties"
            :rows-per-page="10"
            row-key="property_id"
            :expand-row-keys.sync="expanded"
            :search-fn="filter_data"
            :default-sort="{prop: 'address', order: 'ascending'}">

            <el-table-column width="40px" label="#" align="center" type="index"/>


            <el-table-column label="Адрес" sortable>
                <template v-slot="scope">
                      <span class="vote-title">
                        {{ scope.row.address }}
                      </span>
                </template>
            </el-table-column>


            <el-table-column sortable label="Кадастровый номер">
                <template v-slot="scope">
                    <span class="vote-title">{{ scope.row.cadNumber }}</span>
                </template>
            </el-table-column>


            <el-table-column width="40px" type="expand">
                <template v-slot:header="scope">
                    <i class="fas el-icon-fa-fw el-icon-fa-eye"/>
                </template>
                <template v-slot="props">

                    <!--==========================================================================-->
                    <!--|| -->
                    <!--|| -->

                    <!--            Адрес-->
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">

                        <dom-editable-label v3 :value="props.row.address" prefix-text="Адреc:" prefix-icon="fas el-icon-fa-map-marker-alt" @input="onSavePropField(props.row.property_id,'address', $event)"/>

                    </el-row>
                    <!--            УК-->
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                        <dom-editable-label v2 :value="propById[props.row.property_id].rulers.length ? propById[props.row.property_id].rulers[0].name : ''"
                                            prefix-text="Управляющая компания:"
                                            :editable="false"
                        />
                    </el-row>
                    <!--            Тип недвиги-->
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                        <dom-editable-label v2 value="Многоквартирный дом" prefix-text="Тип недвижимости:" :editable="false"/>
                    </el-row>
                    <!--            Регион РФ-->
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">

                        <dom-selectable-label v2
                                              :value="propById[props.row.property_id].region.regionId"
                                              :items="regions || []"
                                              prefix-text="Регион РФ:"
                                              value-key="regionId"
                                              text-key="regionNm"
                                              use-key
                                              @input="onSavePropRegion(props.row.property_id, $event)"
                        />
                    </el-row>
                    <!--            Кадастровый номер-->
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                        <dom-editable-label v2 :value="propById[props.row.property_id].cadNumber" prefix-text="Кадастровый номер:" @input="onSavePropField(props.row.property_id,'cadNumber', $event)"/>
                    </el-row>

                    <template v-if="!isMobile">
                        <!--                    блок жилых помещений-->
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <el-col>
                                <dom-editable-label v2 :value="propById[props.row.property_id].flatsCount" prefix-text="Количество квартир в доме:" type="number" @input="onSavePropField(props.row.property_id,'flatsCount', $event, 'integer')"/>
                            </el-col>
                            <el-col>
                                <dom-editable-label v2 :value="propById[props.row.property_id].areaTotal" prefix-text="Общая площадь:" type="number" @input="onSavePropField(props.row.property_id,'areaTotal', $event, 'decimal')"/>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" :gutter="10" class="realty_row">
                            <el-col>
                                <dom-editable-label v2 :value="propById[props.row.property_id].areaLiving" prefix-text="Общая площадь жилых помещений:"  type="number" @input="onSavePropField(props.row.property_id,'areaLiving', $event, 'decimal')"/>
                            </el-col>
                            <el-col>
                                <dom-editable-label v2 :value="propById[props.row.property_id].areaNonProperty" prefix-text="Общая площадь нежилых помещений:" type="number"  @input="onSavePropField(props.row.property_id,'areaNonProperty', $event, 'decimal')"/>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" :gutter="10" class="realty_row">
                            <el-col>
                                <dom-editable-label v2 :value="propById[props.row.property_id].votingDuration" prefix-text="Продолжительность общего собрания собственников:" type="number" @input="onSavePropField(props.row.property_id,'votingDuration', $event, 'integer')"/>
                            </el-col>
                            <el-col>
                                <dom-editable-label v2 :value="props.row.admin && props.row.admin.name" prefix-text="Админ ОСС:" :editable="false"/>
                            </el-col>
                            <el-col>
                                <dom-editable-label v2 :value="props.row.adminPhoneNumber " prefix-text="Контактные телефоны админа ОСС:" @input="onSavePropField(props.row.property_id,'adminPhoneNumber', $event)"/>
                            </el-col>
                        </el-row>
                    </template>

                    <!--                    блок жилых помещений mobile-->
                    <template v-else>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="propById[props.row.property_id].flatsCount" prefix-text="Количество квартир в доме:" type="number" @input="onSavePropField(props.row.property_id,'flatsCount', $event, 'integer')"/>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="propById[props.row.property_id].areaTotal" prefix-text="Общая площадь:" type="number" @input="onSavePropField(props.row.property_id,'areaTotal', $event, 'decimal')"/>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="propById[props.row.property_id].areaLiving" prefix-text="Общая площадь жилых помещений:"  type="number" @input="onSavePropField(props.row.property_id,'areaLiving', $event, 'decimal')"/>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="propById[props.row.property_id].areaNonProperty" prefix-text="Общая площадь нежилых помещений:" type="number" @input="onSavePropField(props.row.property_id,'areaNonProperty', $event, 'decimal')"/>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="propById[props.row.property_id].votingDuration" prefix-text="Продолжительность общего собрания собственников:" type="number" @input="onSavePropField(props.row.property_id,'votingDuration', $event, 'integer')"/>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="props.row.admin && props.row.admin.name" prefix-text="Админ ОСС:" :editable="false"/>
                        </el-row>
                        <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                            <dom-editable-label v2 :value="props.row.adminPhoneNumber " prefix-text="Контактные телефоны админа ОСС:" @input="onSavePropField(props.row.property_id,'adminPhoneNumber', $event)"/>
                        </el-row>
                    </template>

                    <el-row type="flex" align="middle" justify="space-between" class="realty_row" >
                        <dom-longtext-label v2 :value="propById[props.row.property_id].materialOrder" prefix-text="Порядок ознакомления с материалами:" @input="onSavePropField(props.row.property_id,'materialOrder', $event)"/>
                    </el-row>
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                        <dom-longtext-label v2 :value="propById[props.row.property_id].decisionOrder" prefix-text="Порядок представления письменных решений:" @input="onSavePropField(props.row.property_id,'decisionOrder', $event)"/>
                    </el-row>
                    <el-row type="flex" align="middle" justify="space-between" class="realty_row">
                        <dom-longtext-label v2 :value="propById[props.row.property_id].announcementOrder" prefix-text="Порядок предоставления сообщений:" @input="onSavePropField(props.row.property_id,'announcementOrder', $event)"/>
                    </el-row>


                    <el-row style="display: flex" :class="{buttons_direction: isMobile}">
                        <el-col style="margin-top: 10px;" :xs="24">
                                 <span class="button-label-black" v-if="isMobile" style="padding-left: 10px;">
                                    Привязанных собственников:
                                    <span style="font-weight: bold;">{{ props.row.owners.length }}</span>
                                </span>
                            <dom-button2
                                style="margin-left: 10px;"
                                left-icon="fas fas el-icon-fa-users"
                                @click="props.row.isOwnersTableVisible = !props.row.isOwnersTableVisible">
                              <span class="mr-30 button-label-black" v-if="!isMobile">
                                    Привязанных собственников:
                                    <span style="font-weight: bold;">{{ props.row.owners.length }}</span>
                                </span>

                                <template v-if="!props.row.isOwnersTableVisible">
                                    <span class="show_button__text mr-10">Посмотреть список</span>
                                    <i class="fas el-icon-fa-eye"/>
                                </template>
                                <template v-else>
                                    <span class="show_button__text mr-10">Скрыть список</span>
                                    <i class="fas el-icon-fa-eye-slash"/>
                                </template>
                            </dom-button2>
                        </el-col>
                        <el-col style="margin-top: 10px;">
                            <dom-button2
                                style="margin-left: 10px;"
                                @click="addRuler(props.row.property_id)">
                                <span class="button-label-blue">Назначить УК</span>
                            </dom-button2>

                            <dom-button2 @click="addAdmin(props.row.property_id)">
                                <span class="button-label-blue">Назначить админ. ОСС</span>
                            </dom-button2>

                            <dom-button2 @click="createOwnersRegistry(props.row.property_id)">
                                <span class="button-label-blue">Сформировать реестр собственников</span>
                            </dom-button2>

                            <dom-button2 v-show="$is_admin() &&  props.row.owners.length"
                                         class="delete-button"
                                         right-icon="fas el-icon-fa-times-circle"
                                         @click="removeProperty(props.row)">
                                <span class="button-label-red">Удалить объект</span>
                            </dom-button2>
                        </el-col>


                    </el-row>


                    <el-table v-if="props.row.isOwnersTableVisible"
                              :data="props.row.owners"
                              border
                              style="width: 100%; height: fit-content; margin-top: 10px;">

                        <el-table-column width="40px" label="#" align="center" type="index"/>
                        <el-table-column label="Пользователь" sortable>
                            <template v-slot="scope">
                                  <span class="vote-title">
                                      <i class="fas el-icon-fa-user" style="color: #1d9ef9"></i>
                                    {{ scope.row.name }}
                                  </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="E-mail" sortable>
                            <template v-slot="scope">
                                  <span class="vote-title">
                                    {{ scope.row.email }}
                                  </span>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!--|| -->
                    <!--|| -->
                    <!--==========================================================================-->

                </template>
            </el-table-column>


            <el-table-column width="40px" v-if="$is_admin()">
                <template v-slot:header="scope">
                    <i class="fas el-icon-fa-fw el-icon-fa-times-circle"/>
                </template>
                <template v-slot="scope">
                    <i class="fas el-icon-fa-fw el-icon-fa-times-circle properties-view-header" @click="removeProperty(scope.row)"/>
                </template>
            </el-table-column>


        </dom-table>



        <AddAdminDialog v-model="addAdminDialog" :property_id="current_property_id"/>
        <add-ruler-dialog v-model="addRulerDialog" :property_id="current_property_id"/>

        <code-confirmation-dialog v-model="removeUserDialog" :request-code="onGetCode" :apply-code="onRemoveProperty"/>

    </el-container>

</template>

<script>

import {mapActions} from "vuex";
import _ from "lodash";
import {mapFields} from "vuex-map-fields";
import DomButton2 from "@/views/elements/DomButton2";
import AddAdminDialog from "@/views/dialogs/AddAdminDialog";
import DomEditableLabel from "@/views/editor/DomEditableLabel";
import DomSelectableLabel from "@/views/editor/DomSelectableLabel";
import DomLongtextLabel from "@/views/editor/DomLongtextLabel";
import api from "@/api";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import AddRulerDialog from "@/views/dialogs/AddRulerDialog";
import DomButtonDecline from "@/views/elements/DomButtonDecline";
import VueScreenSize from "vue-screen-size";
import DomTable from "@/views/elements/DomTable";

export default {
    name: "Properties",
    mixins: [VueScreenSize.VueScreenSizeMixin],
    components: {DomTable, DomButtonDecline, AddRulerDialog, CodeConfirmationDialog, DomLongtextLabel, DomSelectableLabel, DomEditableLabel, DomButton2, AddAdminDialog},
    data: () => ({
        perPage: 10,
        addAdminDialog: false,
        addRulerDialog: false,
        removeUserDialog: false,
        current_property_id: false,
        expanded: []
    }),
    mounted() {
        this.getAll()
    },
    watch: {
        expanded(expanded) {
            console.log('expanded', expanded)
        },
    },
    asyncComputed: {
        regions() {
            return this.getRegions().then(res => _.orderBy(res, 'regionNm'))
        }
    },
    computed: {
        ...mapFields("property", ['currentPage', 'properties', 'search']),

        isMobile() {
            return this.$vssWidth < 1300
        },

        propById() {
            return _.keyBy(this.properties || [], 'property_id')
        },

    },

    methods: {
        ...mapActions("property", ["getAll", "save_property_field", "save_property_region"]),
        ...mapActions("meta", ["getRegions"]),
        ...mapActions("reports", {
            report_createPropOwnersRegistry: "createPropOwnersRegistry",
        }),

        filter_data(row) {
            const query = (this.search || "").toLowerCase()
            const check = field => ("" + field).toLowerCase().includes(query)

            return check(row.address)
        },

        onSavePropField(property_id, field, value, datatype) {
            console.log('onSavePropField', field, value, datatype)
            this.save_property_field({property_id, field, value, datatype})
                .then(() => this.getAll())
        },

        onSavePropRegion(property_id, value) {
            console.log('onSavePropRegion', value)
            this.save_property_region({property_id, value})
                .then(() => this.getAll())
        },

        addRuler(property_id) {
            this.addRulerDialog = true;
            this.current_property_id = property_id
        },

        addAdmin(property_id) {
            this.addAdminDialog = true;
            this.current_property_id = property_id
        },

        removeProperty({property_id}) {
            this.current_property_id = property_id;
            this.removeUserDialog = true;
        },

        onGetCode() {
            return api.property.getCode(this.current_property_id)
        },

        onRemoveProperty(code) {
            return api.property.remove(code, this.current_property_id)
                .then(() => this.getAll())
        },

        createOwnersRegistry(property_id) {
            this.report_createPropOwnersRegistry({property_id})
        },

    },
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.buttons_direction {
    flex-direction: column-reverse;
}

.buttons_margin_mobile {
    margin-left: -50px;
}

.realty_row {
    background: $dom-light-gray;
    padding: 3px;
    border-radius: 15px;
    margin-bottom: 10px;
}

.show_button__text {
    color: $dom-light-blue;
    font-weight: bold;
    cursor: pointer;
}

.dom-button-2 {
    border: 2px solid $dom-light-blue;
    border-radius: 15px;
    padding: 5px 15px;
    margin-top: 5px;

    ::v-deep {

        span {
            display: flex;
            align-items: center;
        }

        .button-label-black,
        .button-label-black:active,
        .button-label-black:hover,
        .button-label-black:focus {
            margin-left: 15px;
            color: $dom-black;
        }

        .button-label-blue,
        .button-label-blue:active,
        .button-label-blue:hover,
        .button-label-blue:focus {
            color: $dom-light-blue;
        }

        i {
            font-size: 16px !important;
            width: 20px;
            color: $dom-light-blue
        }
    }

}

.dom-button-2.delete-button {
    border: 2px solid $dom-red;

    ::v-deep {

        .button-label-red {
            margin: 0 25px 0 15px;
            color: $dom-red;
        }

        i {
            color: $dom-red
        }
    }

}

.realty_row_delete_object {
    border: 2px solid $dom-red;
    color: $dom-red;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;
    padding: 5px 5px 5px 15px;
    width: 200px;

    i {
        font-size: 16px;
        width: 20px;
    }
}

.realty_row_add_admin {
    border: 2px solid $dom-light-blue;
    color: $dom-light-blue;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;
    padding: 5px 5px 5px 15px;
    width: 200px;
    text-align: center;
}

.realty_row i {
    color: $dom-light-blue;
}

::v-deep .realty_row .edit__icon {
    color: $dom-gray;
    font-size: 19px;
}

.properties-view-header {
    color: $dom-red;
    margin-top: 6px;
}

::v-deep .el-icon-arrow-right {
    position: initial;
    margin: 0;
    color: $dom-light-blue;
}

//noinspection CssNoGenericFontName
::v-deep .el-table__expand-icon i::before {
    content: "\f06e";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    font-weight: bold;
}

//noinspection CssNoGenericFontName
::v-deep .el-table__expand-icon--expanded i::before {
    content: "\f070";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    font-weight: bold;
}

::v-deep .el-table__expand-icon--expanded {
    transform: none;
}

.dom-editable-label {
    height: 100%;
}

.dom-selectable-label {
    height: 100%;
}

.dom-longtext-label.v2 ::v-deep .prefix-text {
    display: block;
}

</style>

