<template>
    <!--
    Диалог назначения УК
    -->
    <el-dialog
        :width="dialogWidth"
        class="new-voting-dialog"
        :class="{dialog_full_display: !$isMobile()}"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :show-close="false"
        :modal-append-to-body="false">

        <template v-slot:title>
            <dom-title-closable value="Назначение управляющей компании" close-text="Завершить без сохранения" @close="onCancel"></dom-title-closable>
        </template>


        <el-tabs tab-position="left" class="tab-pane-wrapper" :value="tab">

            <!--            Шаг 1 - email -->
            <el-tab-pane class="tab-pane" name="1">
                <new-vote-tab-label slot="label" number="1" title="Email пользователя"/>
                <el-row style="margin: 12px 0">
                    <dom-input v-model="itemParams.email" placeholder="email" icon-left="fas el-icon-fa-user"/>
                </el-row>
                <dom-button-accept @click="changeTabFrom1to2" :disabled="!form1isCompleted" label="Сохранить и продолжить"/>
            </el-tab-pane>


            <!--            Шаг 2 (2) - заполнение данных администратора -->
            <el-tab-pane class="tab-pane" name="2" v-if="completedStep > 1 ">
                <new-vote-tab-label slot="label" number="2" title="Данные управляющей компании"/>

                <!-- Юр лицо-->
                <el-row style="margin: 12px 0">
                    <dom-input v-model="itemParams.name" placeholder="Наименование" icon-left="fas el-icon-fa-user"/>
                </el-row>
                <el-row style="margin: 12px 0">
                    <dom-input v-model="itemParams.inn" placeholder="ИНН" icon-left="fas el-icon-fa-exclamation-circle"/>
                </el-row>

                <dom-button-accept @click="onFinish" :disabled="!form2isCompleted">Сохранить и завершить</dom-button-accept>
            </el-tab-pane>

        </el-tabs>

    </el-dialog>

</template>

<script>

import Toggleable from "@/views/mixins/Toggleable";
import DomDropdown from "@/views/elements/DomDropdown";
import NewVoteTabLabel from "@/views/components/NewVoteTabLabel";
import DomDatepicker from "@/views/elements/DomDatepicker";
import DomButton from "@/views/elements/DomButton";
import DomDropdownComplex from "@/views/elements/DomDropdownComplex";
import DomTextarea from "@/views/elements/DomTextarea";
import DomHuman from "@/views/elements/DomHuman";
import DomInput from "@/views/elements/DomInput";
import {mapActions} from "vuex";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import VueScreenSize from "vue-screen-size";

export default {
    components: {
        DomTitleClosable,
        DomButtonAccept,
        DomInput, DomHuman, DomTextarea, DomDropdownComplex, DomButton, DomDatepicker, NewVoteTabLabel, DomDropdown,
    },
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],

    props: {
        property_id: {require: true}
    },

    data: () => ({
        itemParams: {

            email: null,
            name: null,
            inn: null,

        },
        tab: '1',
        completedStep: 1,
    }),

    computed: {
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },
        form1isCompleted() {
            return !!this.itemParams.email
        },

        form2isCompleted() {
            let itemParams = this.itemParams;
            return !!itemParams.name && !!itemParams.inn
        },

    },

    watch: {
        'itemParams.email'() {
            this.completedStep = 1;
            this.tab = '1';
        }
    },

    methods: {
        ...mapActions("user", ["get_user"]),
        ...mapActions("property", ["getAll"]),

        changeTabFrom1to2() {

            const allowed_forms = ['owner_manager_org', 'manager_org']

            this.$api.users.getUserByEmail(this.itemParams.email)
                .then(user => {

                    if (!allowed_forms.includes(user.role.roleCd)) {
                        this.$message.error("Пользователь должен быть управляющей компанией")
                        return
                    }

                    _.assign(this.itemParams, user)

                    this.tab = '2';
                    this.completedStep = 2;
                })

        },

        onCancel() {
            this.itemParams.email = null;
            this.completedStep = 1;
            this.tab = '1';
            this.isActive = false;
        },

        onFinish() {
            this.$api.property.setRuler(this.property_id, this.itemParams)
                .then(() => {
                    this.$message.success("УК успешно сохранена")
                    this.getAll();
                    this.onCancel();
                })
        },
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";


.title {
    font-size: 22px;
    font-weight: bold;
}

.close-icon {
    font-size: 24px;
}

.el-dialog__wrapper.warning-dialog {
    ::v-deep .el-dialog__header {
        background: $dom-light-gray;
        height: 34px;
    }

    ::v-deep .el-dialog__title {
        font-size: 26px;
    }
}

.question {
    ::v-deep .question__index {
        width: 30px;
        height: 30px;
        line-height: 30px;
        color: $dom-white;
        background: $dom-green;
        font-size: 20px;
        border-radius: 50%;
        text-align: center;
    }

    .question__files {
        margin: 15px 0 0 0;
        padding: 0 0 0 35px;
        min-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: $dom-light-blue;
    }

    .question__files:last-child {
        margin-bottom: 0;
    }

    .question__files_delete {
        color: $dom-gray;
        font-size: 16px;
        margin-left: 5px;
    }

    .question__files_delete:hover {
        cursor: pointer;
    }


    .question__delete {
        color: $dom-red;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $dom-red;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
    }

    .question__delete:hover {
        cursor: pointer;
    }

    .question__title {
        background: $dom-light-gray;
        border-radius: 3px;
        padding: 7px;
        font-size: 16px;

        &.el-textarea {
            padding: 0;

            ::v-deep textarea {
                padding-left: 30px;
            }

            & + i {
                position: absolute;
                left: 45px;
                top: 7px;
            }
        }
    }

    .question__upload_button {
        padding: 5px 0;
        vertical-align: center;
        text-align: center;
        color: $dom-light-blue;
        border-radius: 5px;
        border: 2px solid $dom-light-blue;

        ::v-deep i {
            font-size: 18px;
        }

        ::v-deep span {
            margin: 0 10px;
            line-height: 20px;
            font-size: 14px;
        }


    }

    .question__title_icon {
        color: $dom-light-blue;
        padding-right: 10px;
        font-size: 20px;
    }
}
@media (max-width: 700px) {
    .new-voting-dialog {
        ::v-deep .el-dialog {
            margin-top: 0 !important;
            height: 100% !important;
        }
    }
}
.dialog_full_display {
    max-height: 70vh;
    margin-top: 35px;
    width: 100%;
}

.new-voting-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }
}

.tab-pane {
    background-color: $dom-light-gray;
    height: 100%;
}

.tab-pane-wrapper {
    background-color: $dom-light-gray;
    height: 100%;

    ::v-deep .el-tabs__item {
        background-color: $dom-light-gray;
        padding: 0;
        height: 80px;
        //width: 250px;
    }

    ::v-deep .el-tabs__content {
        max-height: 100%;
        padding: 20px;
    }
}


</style>
